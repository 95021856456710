// src/components/Checkout.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Checkout = () => {
  const [products, setProducts] = useState([]);
  const [billingAddress, setBillingAddress] = useState({
    firstname: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [paymentInfo, setPaymentInfo] = useState({
    cardname: "",
    cardnumber: "",
    expmonth: "",
    expyear: "",
    cvv: "",
  });
  const { id } = useParams(); // Product ID from URL parameter

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/api/products/${(id)}`
        );
        setProducts(response.data);
        console.log("daasdskdm",response.data)
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  // Handle change in billing address inputs
  const handleBillingChange = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
  };

  // Handle change in payment info inputs
  const handlePaymentChange = (e) => {
    setPaymentInfo({ ...paymentInfo, [e.target.name]: e.target.value });
  };

  // Submit form for checkout and payment
  const handleSubmit = async (e) => {
    e.preventDefault();

    const productIds = [id]; // Assuming a single product checkout based on URL

    try {
      // Create an order on Razorpay
      const orderResponse = await axios.post(
        "http://localhost:5000/api/checkout/create-order",
        {
          amount: products.reduce((total, product) => total + product.price, 0),
          currency: "INR",
        }
      );

      const orderData = orderResponse.data;

      // Configure Razorpay checkout
      const options = {
        key: "rzp_test_vbRBZK4S1mlose", // Replace with your Razorpay Key ID
        amount: orderData.amount,
        currency: orderData.currency,
        name: "NKbhother",
        description: "Order Checkout",
        order_id: orderData.id,
        handler: async function (response) {
          // Handle successful payment
          console.log("Payment successful:", response);
          alert("Payment successful!");

          // Save order and billing information after payment
          await axios.post("http://localhost:5000/api/checkout", {
            billingAddress,
            paymentInfo,
            productIds,
          });
        },
        prefill: {
          name: billingAddress.firstname,
          email: billingAddress.email,
          contact: "9999999999",
        },
        notes: {
          address: billingAddress.address,
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("Checkout failed. Please try again.");
    }
  };

  return (
    <div className="container checkoutPage">
      <div
        className="row"
        style={{ margin: "50px 0px", justifyContent: "space-between" }}
      >
        <div className="containered" style={{ width: "49%", padding: "20px" }}>
          <form onSubmit={handleSubmit}>
            {/* Billing Address Inputs */}
            <div className="row">
              <div className="col-50">
                <h3>Billing Address</h3>
                <label htmlFor="fname">
                  <i className="fa fa-user"></i> Full Name
                </label>
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="John M. Doe"
                  value={billingAddress.firstname}
                  onChange={handleBillingChange}
                  required
                />
                <label htmlFor="email">
                  <i className="fa fa-envelope"></i> Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="john@example.com"
                  value={billingAddress.email}
                  onChange={handleBillingChange}
                  required
                />
                <label htmlFor="adr">
                  <i className="fa fa-address-card-o"></i> Address
                </label>
                <input
                  type="text"
                  id="adr"
                  name="address"
                  placeholder="542 W. 15th Street"
                  value={billingAddress.address}
                  onChange={handleBillingChange}
                  required
                />
                <label htmlFor="city">
                  <i className="fa fa-institution"></i> City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="New York"
                  value={billingAddress.city}
                  onChange={handleBillingChange}
                  required
                />
                <div className="row">
                  <div className="col-50">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      placeholder="NY"
                      value={billingAddress.state}
                      onChange={handleBillingChange}
                      required
                    />
                  </div>
                  <div className="col-50">
                    <label htmlFor="zip">Zip</label>
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="10001"
                      value={billingAddress.zip}
                      onChange={handleBillingChange}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Payment Information Inputs */}
              <div className="col-50">
                <h3>Payment</h3>
                <label htmlFor="cardname">Accepted Cards</label>
                <div className="icon-container">
                  <i className="fa fa-cc-visa" style={{ color: "navy" }}></i>
                  <i className="fa fa-cc-amex" style={{ color: "blue" }}></i>
                  <i
                    className="fa fa-cc-mastercard"
                    style={{ color: "red" }}
                  ></i>
                  <i
                    className="fa fa-cc-discover"
                    style={{ color: "orange" }}
                  ></i>
                </div>
                <label htmlFor="cardname">Name on Card</label>
                <input
                  type="text"
                  id="cardname"
                  name="cardname"
                  placeholder="John More Doe"
                  value={paymentInfo.cardname}
                  onChange={handlePaymentChange}
                  required
                />
                <label htmlFor="cardnumber">Credit card number</label>
                <input
                  type="text"
                  id="cardnumber"
                  name="cardnumber"
                  placeholder="1111-2222-3333-4444"
                  value={paymentInfo.cardnumber}
                  onChange={handlePaymentChange}
                  required
                />
                <label htmlFor="expmonth">Exp Month</label>
                <input
                  type="text"
                  id="expmonth"
                  name="expmonth"
                  placeholder="September"
                  value={paymentInfo.expmonth}
                  onChange={handlePaymentChange}
                  required
                />
                <div className="row">
                  <div className="col-50">
                    <label htmlFor="expyear">Exp Year</label>
                    <input
                      type="text"
                      id="expyear"
                      name="expyear"
                      placeholder="2018"
                      value={paymentInfo.expyear}
                      onChange={handlePaymentChange}
                      required
                    />
                  </div>
                  <div className="col-50">
                    <label htmlFor="cvv">CVV</label>
                    <input
                      type="text"
                      id="cvv"
                      name="cvv"
                      placeholder="352"
                      value={paymentInfo.cvv}
                      onChange={handlePaymentChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <input type="submit" value="Continue to Checkout" className="btn" />
          </form>
        </div>
        <div className="containered" style={{ width: "49%", padding: "20px" }}>
          <table style={{width:"100%"}}>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td><img src={products?.imageUrl} style={{borderRadius:"100%",height:"50px",width:"50px",objectFit:"cover"}} /></td>
                <td>{products?.price}</td>
                <td>{products?.quantity}</td>
                <td>{products?.name}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
