import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/cart");
        setCartItems(response.data);
      } catch (err) {
        setError("Error fetching cart items");
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  const updateQuantity = async (id, newQuantity) => {
    if (newQuantity < 1) {
      alert("Quantity must be at least 1");
      return;
    }

    try {
      const response = await axios.post(
        "http://localhost:5000/api/cart/update",
        {
          id: id,
          quantity: newQuantity,
        }
      );
      // Update the cart items in state based on the response
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productId === id ? { ...item, quantity: newQuantity } : item
        )
      );
    } catch (err) {
      console.error("Error updating quantity:", err);
      alert("Failed to update quantity");
    }
  };

  const removeItem = async (id) => {
    try {
      const response = await axios.delete(
        `http://localhost:5000/api/cart/${id}`
      );
      console.log(response); // Log the response from the server
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.productId !== id)
      );
    } catch (err) {
      console.error("Error removing item:", err);
      if (err.response) {
        // Log the response data to get more details on the error
        console.error("Server responded with:", err.response.data);
      }
      alert("Failed to remove item");
    }
  };
  const proceedToCheckout = async () => {
    try {
      const response = await axios.post(
        "http://localhost:5000/api/cart/checkout"
      );
      alert(response.data.message); // Show success message
      console.log("response", response);
    } catch (err) {
      console.error("Checkout failed:", err);
      alert("Failed to proceed to checkout");
    }
  };

  const calculateTotal = () => {
    return cartItems
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  };

  if (loading) {
    return <div>Loading cart...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="cart-page " style={{ display: "block" }}>
      <h2 style={{textAlign:"left"}} >Your Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div>
          <ul className="cart-list">
            {cartItems.map((item) => (
              <li
                style={{ float: "none" ,border:"none"}}
                key={item.productId}
                className="cart-item color_bg"
              >
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className="cart-item-image"
                />
                <div className="cart-item-details">
                  <h2
                    className="text_ellipsis"
                    style={{ margin: "0px", fontSize: "20px" }}
                  >
                    {item.title}
                  </h2>
                  <p style={{ margin: "0px" }}>${item.price.toFixed(2)} each</p>
                  <input
                    type="number"
                    min="1"
                    value={item.quantity}
                    onChange={(e) =>
                      updateQuantity(item.productId, Number(e.target.value))
                    }
                    className="quantity-input"
                  />
                  <button
                    onClick={() => removeItem(item.productId)}
                    className="remove-button"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div style={{display:"flex",justifyContent:"right",alignItems:"center",gap:"10px"}}>
        <h5 style={{margin:"0px"}} >Total: ${calculateTotal()}</h5>
        <Link to="/CheckoutPage" style={{ width: "200px", display: "block" }}>
          <button
            className="submit-button"
            style={{marginTop:"5px"}}
            onClick={proceedToCheckout}
          >
            Proceed to Checkout
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CartPage;
