import React from "react";
import shopping_jewelry from "../../Images/shopping_jewelry.png";

const CustomBanner = ({ data }) => {
  return (
    <div>
      {data?.map((itmes) => (
        <div style={{position:'relative',justifyContent:"center",alignItems:'center',display:"flex"}}>
          <img src={shopping_jewelry} style={{width:"100%",height:"70vh",objectFit:"cover",filter:"brightness(0.5)"}} />
          <section className="about-intro container">
            <h1>{itmes.name}</h1>
            <p>{itmes.Directions}</p>
          </section>
        </div>
      ))}
    </div>
  );
};
export default CustomBanner;
