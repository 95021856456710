// About.js
import React from "react";
import CustomBanner from "../CustomBanner/CustomBanner";
import { BiDirections } from "react-icons/bi";
const data = [
  {
    name:"About Us",
    Directions:" Welcome to E-Shop, your number one source for all things [Product Category]. We're dedicated to giving you the very best of [Product Category], with a focus on quality, customer service, and uniqueness."
  }
]
const About = () => {
  return (
    <div className="about-container">
      <CustomBanner data={data} />
      <div className="container">
        
        <section className="about-mission">
          <h2>Our Mission</h2>
          <p>
            Our mission is to provide our customers with top-quality products
            that bring value to their lives. We strive to build long-term
            relationships based on trust, innovation, and a shared commitment to
            success.
          </p>
        </section>

        {/* Team Section */}
        <section className="about-team">
          <h2>Meet Our Team</h2>
          <div className="team-grid">
            <div className="team-member">
              <img src="https://via.placeholder.com/150" alt="Team Member 1" />
              <h3>John Doe</h3>
              <p>Founder & CEO</p>
            </div>

            <div className="team-member">
              <img src="https://via.placeholder.com/150" alt="Team Member 2" />
              <h3>Jane Smith</h3>
              <p>Head of Marketing</p>
            </div>

            <div className="team-member">
              <img src="https://via.placeholder.com/150" alt="Team Member 3" />
              <h3>Mike Johnson</h3>
              <p>Lead Developer</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
