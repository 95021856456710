// App.js
import React,{useEffect}from "react";
import { Routes, Route, useLocation } from "react-router-dom"; // No need to import BrowserRouter here
import Home from "./screen/Home/Home";
import Login from "./screen/Login/Login";
import Register from "./screen/Login/Register";
import ForgotPassword from "./screen/Login/ForgotPassword";
import ResetPassword from "./screen/Login/ResetPassword";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import About from "./screen/About/About";
import Contact from "./screen/Contact/Contact";
import ProductList from "./screen/ProductList/ProductList";
import CartPage from "./screen/Cart/Cart";
import CheckoutPage from "./screen/CheckoutPage/CheckoutPage";
import AddProduct from "./screen/ProductList/AddProduct";
import ProductDetail from "./screen/ProductList/ProductDetail";
import OrderSummaryPage from "./screen/OrderSummaryPage/OrderSummaryPage";
import BuyNow from "./screen/BuyNow/BuyNow";
import "./App.css";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [location]); // This will run every time the location changes

  return (
    <div style={{background:"black",color:"white"}}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkoutPage/:id" element={<CheckoutPage />} />
        <Route path="/AddProduct" element={<AddProduct />} />
        <Route path="/ProductDetail/:id" element={<ProductDetail />} />
        <Route path="/order-summary" element={<OrderSummaryPage />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/BuyNow" element={<BuyNow />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
