// Home.js
import React from "react";
import Aesthetie from "../../Images/Aesthetic_earrings.jpg";
import Young from "../../Images/Young.jpg";
import still_life from "../../Images/still_life.jpg";
import stylish_female from "../../Images/stylish_female.jpg";
import { useNavigate } from "react-router-dom";
const Home = () => {

  const navigate = useNavigate();
  const handleChange = () =>{
    navigate('/products')
  }
  const handleChangeSign = () =>{
    navigate('/Register')

  }
  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content " style={{textAlign:"center"}}>
          <h1>Welcome to NKbhother shop</h1>
          {/* <p>Your one-stop shop for the latest and greatest products!</p> */}
          <button onClick={handleChange} className="shop-now-btn">Shop Now</button>
        </div>
      </section>

      {/* Featured Products */}
      <section className="featured-products">
        <h2>Featured Products</h2>
        <div className="products-grid">
          <div className="product-card">
            <img src={Aesthetie} alt="Product 1" />
            <h3>Product 1</h3>
            {/* <p>$19.99</p> */}
            {/* <button className="buy-now-btn">Buy Now</button> */}
          </div>

          <div className="product-card">
            <img src={Young} alt="Product 2" />
            <h3>Product 2</h3>
          </div>

          <div className="product-card">
            <img src={still_life} alt="Product 3" />
            <h3>Product 3</h3>
          </div>
          <div className="product-card">
            <img src={stylish_female} alt="Product 3" />
            <h3>Product 3</h3>
          </div>
        </div>
      </section>

      <section style={{textAlign:"center",width:"100%"}}>
        <button onClick={handleChange} className="sign-up-btn">View More</button>
      </section>

      <section className="cta">
        <div style={{ position: "relative" }}>
          <h2>Sign Up for Exclusive Offers!</h2>
          <p>Get the latest news, updates, and special discounts.</p>
          <button onClick={handleChangeSign} className="sign-up-btn">Sign Up</button>
        </div>
      </section>
    </div>
  );
};

export default Home;
