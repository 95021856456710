// src/components/Login.js
import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/userSlice';
const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/api/auth/login",
        {
          username,
          password,
        }
      );

      setMessage("Login successful!"); 
      const { token, user } = response.data;
      dispatch(setUserData({ userData: user, token }));

    } catch (error) {
      console.error("Login Error:", error);
      setMessage(error.response?.data?.message || "Login failed"); 
    }
  };

  return (
    <div className="login-container" style={{ flexWrap: "wrap" }}>
      <form onSubmit={handleSubmit} className="form-group">
        <h2>Login</h2>

        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="password" 
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="input-field"
        />
        <Link
          to="/ForgotPassword"
          style={{
            textAlign: "right",
            paddingTop: "10px",
            display: "block",
            color: "white",
            textDecoration: "underline",
          }}
        >
          <h6>Forgot Password</h6>
        </Link>
        <button type="submit" className="submit-button">
          Login
        </button>
        <h6 style={{ textAlign: "center",marginTop:"30px" }}>Don't have an account? <Link to="/Register" style={{color:"wheat",textDecoration:"underline"}} >Sign up</Link></h6>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default Login;
