// src/components/ProductPage.js
import React, { useEffect, useState } from 'react';
import '../../index.css'; // Ensure you have the correct path for your CSS file
import Slider from 'react-slick';
import axios from 'axios'; // Ensure axios is installed
import { Link, useParams } from "react-router-dom";


const ProductPage = () => {
    const { id } = useParams(); // Get the product ID from the URL

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                // Replace with the correct product ID you want to fetch
                const response = await axios.get(`http://localhost:5000/api/products/${id}`);
                setProduct(response.data);
            } catch (err) {
                setError("Error fetching product details");
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, []);

    const addToCart = async (productId, quantity = 1) => {
        try {
          const response = await axios.post("http://localhost:5000/api/cart", {
            productId,
            quantity,
          });
          alert(response.data.message); // Show success message
        } catch (error) {
          console.error("Error adding to cart:", error);
          alert("There was an error adding the item to your cart.");
        }
      };
    


    if (loading) {
        return <div className="loading">Loading product...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    if (!product) {
        return <div>No product found.</div>;
    }

    return (
        <div className="container-fluid mt-2 mb-3">
            <div className="row no-gutters">
                <div className="col-md-5 pr-2">
                    <div className="card">
                        <Slider {...sliderSettings}>
                            {product.images.map((src, index) => (
                                <div key={index}>
                                    <img src={src} alt={`Thumbnail ${index + 1}`} style={{ width: '100%' }} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className="card mt-2">
                        <h6>Reviews</h6>
                        <div className="d-flex flex-row">
                            <div className="stars">
                                {[...Array(Math.round(product.rating))].map((_, index) => (
                                    <i key={index} className="fa fa-star"></i>
                                ))}
                            </div>
                            <span className="ml-1 font-weight-bold">{product.rating}</span>
                        </div>
                        <hr />
                        {/* Additional review-related content */}
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card">
                        <div className="d-flex flex-row align-items-center">
                            <div className="p-ratings">
                                {[...Array(Math.round(product.rating))].map((_, index) => (
                                    <i key={index} className="fa fa-star"></i>
                                ))}
                            </div>
                            <span className="ml-1">{product.rating}</span>
                        </div>
                        <div className="about">
                            <span className="font-weight-bold">{product.title}</span>
                            <h4 className="font-weight-bold">${product.price.toFixed(2)}</h4>
                        </div>
                        <div className="buttons" style={{display:"flex",flexWrap:"wrap",gap:"20px"}}>
                            <button  onClick={() => addToCart(product._id)} className="btn btn-outline-warning btn-long cart">Add to Cart</button>
                            <Link to={`/checkoutPage/${product._id}`}> <button className="btn btn-warning btn-long buy">Buy it Now</button></Link>
                            <button className="btn btn-light wishlist"><i className="fa fa-heart"></i></button>
                        </div>
                        <hr />
                        <div className="product-description">
                            <div>
                                <span className="font-weight-bold">Color:</span><span> {product.color}</span>
                            </div>
                            <div className="my-color">
                                {product.availableColors.map((color, index) => (
                                    <label className="radio" key={index}>
                                        <input type="radio" name="color" value={color} />
                                        <span className={color}></span>
                                    </label>
                                ))}
                            </div>
                            <div className="mt-2">
                                <span className="font-weight-bold">Description</span>
                                <p>{product.description}</p>
                                <div className="bullets">
                                    {product.bullets.map((bullet, index) => (
                                        <div className="d-flex align-items-center" key={index}>
                                            <span className="dot"></span>
                                            <span className="bullet-text">{bullet}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className="font-weight-bold">Store</span>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={product.storeImage} className="rounded-circle store-image" alt="Store" />
                                    <div className="d-flex flex-column ml-1 comment-profile">
                                        <div className="comment-ratings">
                                            {[...Array(4)].map((_, index) => (
                                                <i key={index} className="fa fa-star"></i>
                                            ))}
                                        </div>
                                        <span className="username">{product.storeName}</span>
                                        <small className="followers">{product.storeFollowers} Followers</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <span>Similar items:</span>
                        <div className="similar-products mt-2 d-flex flex-row">
                            {product.similarItems.map((item, index) => (
                                <div className="card border p-1" style={{ width: '9rem', marginRight: '3px' }} key={index}>
                                    <img src={item.img} className="card-img-top" alt={`Similar item ${index + 1}`} />
                                    <div className="card-body">
                                        <h6 className="card-title">{item.price}</h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
