import React, { useState } from "react";
import axios from "axios";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(
        "http://localhost:5000/api/auth/reset-password",
        {
          email,
          token: otp, // Assuming otp is the OTP entered by the user
          newPassword,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(
        error.response
          ? error.response.data.message
          : "Error resetting password"
      );
    }
  };

  return (
    <div className="login-container">
      <form className="form-group">
      <h2>Reset Password</h2>

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="input-field"
        />
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="Enter OTP"
          className="input-field"
        />
        <input
          type="password" // Use type "password" for security
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          className="input-field"
        />
        <button onClick={handleResetPassword} className="submit-button">
          Reset Password
        </button>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
}

export default ResetPassword;
