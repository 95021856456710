import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null,
    token: null,
  },
  reducers: {
    setUserData(state, action) {
      state.userData = action.payload.userData;
      state.token = action.payload.token;
    },
    clearUserData(state) {
      state.userData = null;
      state.token = null;
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;
