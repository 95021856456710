import React, { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      // Make API call
      const response = await axios.post(
        "http://localhost:5000/api/auth/forgot-password",
        { email }
      );
      // navigate("/ResetPassword");
      setMessage(response.data.message);
    } catch (error) {
      setError(error.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="login-container">
      <form
        onSubmit={handleSubmit}
        className="form-group"
        style={{ width: "100%" }}
      >
        <h2 style={{ textAlign: "left" }}>Forgot Password</h2>

        {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            /> */}

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="input-field"
        />
        <button className="submit-button">Send OTP</button>
        {message && (
        <div >
          {message}
        </div>
      )}

      {/* Display error message */}
      {error && (
        <d>
          {error}
        </d>
      )}
      </form>

      {/* Display success message */}
    
    </div>
  );
};

export default ForgotPassword;
