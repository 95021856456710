// src/components/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [message, setMessage] = useState('');
  const [imagePreview, setImagePreview] = useState('');

 
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('email', email);
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    try {
      const response = await axios.post('http://localhost:5000/api/auth/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Registration Error:', error);
      setMessage(error.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}  className='form-group' >
      <h2>Register</h2>

        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="password" // Change to type password for security
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="input-field"
        />
       
          <label className="file-input-label">
          <input
            type="file"
            onChange={handleImageChange}
            className="file-input"
          />
          <i className="fas fa-upload upload-icon"></i>
          
        </label>
        {imagePreview && (
          <div className="image-preview">
            <img src={imagePreview} alt="Preview" style={{ width: '100px', height: '100px',borderRadius:"200px", objectFit: 'cover' }} />
          </div>
        )}
        <button type="submit" className="submit-button">Register</button>
        {message && <p className="message">{message}</p>}
        <h6 style={{ textAlign: "center",marginTop:"30px" }}>Don't have an account? <Link to="/Login" style={{color:"wheat",textDecoration:"underline"}} >Login</Link></h6>

      </form>
    </div>
  );
};

export default Register;
