// src/components/OrderSummaryPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const OrderSummaryPage = ({ orderDetails }) => {
    if (!orderDetails) {
        return <div>No order details available.</div>;
    }

    return (
        <div className="order-summary-page">
            <h1>Order Summary</h1>
            <h2>Deliver To:</h2>
            <p>Name: {orderDetails.deliveryName}</p>
            <p>Address: {orderDetails.deliveryAddress}</p>
            <p>City: {orderDetails.city}</p>
            <p>Postal Code: {orderDetails.postalCode}</p>
            <p>Country: {orderDetails.country}</p>

            <h2>Order Details:</h2>
            <ul className="order-details-list">
                {orderDetails.items.map((item) => (
                    <li key={item.productId} className="order-item">
                        <img src={item.imageUrl} alt={item.title} className="order-item-image" />
                        <div className="order-item-details">
                            <h3>{item.title}</h3>
                            <p>Price: ${item.price.toFixed(2)} x {item.quantity}</p>
                        </div>
                    </li>
                ))}
            </ul>

            <h3>Total: ${orderDetails.total.toFixed(2)}</h3>
            <Link to="/home" className="home-button">Return to Home</Link>
        </div>
    );
};

export default OrderSummaryPage;
