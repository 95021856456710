// Footer.js
import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import PngItem_Logo from "../Images/PngItem_Logo.png"

const Footer = () => {
  return (
    <footer style={{ background: "rgb(48, 31, 14)" }}>
      <div className="container">
      <img src={PngItem_Logo}  style={{width:"100px",paddingTop:'30px',paddingLeft:"20px"}}/>

        <div className="footer ">
          <div className="footer-section">

            <h4>Quick Links</h4>
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="footer-section">
            <h4>Contact</h4>
            <p>Email: support@eshop.com</p>
            <p>Phone: +1 234 567 890</p>
            <p>Address: 123 E-Shop St, Online City</p>
          </div>

          {/* Social Media Links */}
          <div className="footer-section">
            <h4>Follow Us</h4>
            <div className="social-media">
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <FaFacebook />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <FaTwitter />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="footer-bottom" style={{ width: "100%", colorL: "white" }}>
        <p style={{ color: "white" }}>
          &copy; 2024 E-Shop. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
