// src/components/ContactPage.js
import React, { useState } from 'react';
import axios from 'axios';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    number: '' // Added number to the state
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:5000/api/contact', formData);
      if (response.status === 201) {
        setStatus('Message sent successfully!');
      } else {
        setStatus('Failed to send the message.');
      }
    } catch (error) {
      console.error("Error:", error.response || error);
      setStatus('There was an error sending your message.');
    }
  };

  return (
    <div className="contact-page">
      <div className="sidebar">
        <h2>Additional Information</h2>
        <p>Here you can add links, FAQs, or other important details for your users.</p>
      </div>
      <div className="form-container">
        <h1 style={{ textAlign: "left" }}>Contact Us</h1>
        {status && <p className="status-message">{status}</p>}
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="number">Phone Number:</label>
            <input
              type="tel" // Use 'tel' for phone numbers
              id="number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
