import React, { useState } from 'react';
import axios from 'axios';

const OrderTracking = () => {
    const [orderId, setOrderId] = useState('');
    const [orderDetails, setOrderDetails] = useState(null);
    const [error, setError] = useState('');

    // Handle change in order ID input
    const handleChange = (e) => {
        setOrderId(e.target.value);
    };

    // Function to fetch order details
    const fetchOrderDetails = async (e) => {
        e.preventDefault();
        setError(''); // Reset error message

        try {
            const response = await axios.get(`http://localhost:5000/api/checkout/track-order/${orderId}`);
            setOrderDetails(response.data);
        } catch (err) {
            console.error('Error fetching order details:', err);
            setError('Order not found or an error occurred. Please check the Order ID.');
            setOrderDetails(null);
        }
    };

    return (
        <div className='container'>
            <h2>Track Your Order</h2>
            <form onSubmit={fetchOrderDetails}>
                <label htmlFor="orderId">Enter Order ID:</label>
                <input
                style={{background:"black"}}
                    type="text"
                    id="orderId"
                    value={orderId}
                    onChange={handleChange}
                    required
                />
                <button type="submit">Track Order</button>
            </form>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            {orderDetails && (
                <div className="order-details">
                    <h3>Order Details</h3>
                    <p><strong>Order ID:</strong> {orderDetails._id}</p>
                    <p><strong>Status:</strong> {orderDetails.status}</p>
                    <h4>Billing Address</h4>
                    <p>{orderDetails.billingAddress.firstname}</p>
                    <p>{orderDetails.billingAddress.email}</p>
                    <p>{orderDetails.billingAddress.address}</p>
                    <p>{orderDetails.billingAddress.city}</p>
                    <p>{orderDetails.billingAddress.state}</p>
                    <p>{orderDetails.billingAddress.zip}</p>
                    <h4>Products:</h4>
                    <ul>
                        {orderDetails.products.map(product => (
                            <li key={product.productId}>
                                {product.name} - ₹{product.price}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default OrderTracking;
