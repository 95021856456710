// src/components/ProductList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAnimating, setIsAnimating] = useState({});
  const [filters, setFilters] = useState({ category: "", priceRange: "" }); // Add filter states

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/products");
        setProducts(response.data);
      } catch (err) {
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const addToCart = async (productId, quantity = 1) => {
    try {
      const response = await axios.post("http://localhost:5000/api/cart", {
        productId,
        quantity,
      });
      alert(response.data.message);

      // Trigger animation
      setIsAnimating((prev) => ({
        ...prev,
        [productId]: true,
      }));
      setTimeout(() => {
        setIsAnimating((prev) => ({
          ...prev,
          [productId]: false,
        }));
      }, 1000);
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("There was an error adding the item to your cart.");
    }
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filteredProducts = products.filter((product) => {
    // Apply filtering logic based on your requirements
    return (
      (filters.category === "" || product.category === filters.category) &&
      (filters.priceRange === "" ||
        (product.price >= parseFloat(filters.priceRange.split("-")[0]) &&
          product.price <= parseFloat(filters.priceRange.split("-")[1])))
    );
  });

  if (loading) {
    return <div className="loading">Loading products...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="product-page">
      <div className="sidebar">
        <h2>Filters</h2>
        <div>
          <h3>Category</h3>
          <select name="category" onChange={handleFilterChange}>
            <option value="">All</option>
            {/* Add category options */}
            <option value="electronics">Electronics</option>
            <option value="fashion">Fashion</option>
            <option value="home">Home</option>
          </select>
        </div>
        <div>
          <h3>Price Range</h3>
          <select name="priceRange" onChange={handleFilterChange}>
            <option value="">All</option>
            <option value="0-50">$0 - $50</option>
            <option value="51-100">$51 - $100</option>
            <option value="101-200">$101 - $200</option>
          </select>
        </div>
      </div>
      <div className="product-container">
        <h2 className="title">Products</h2>
        <div className="product-grid">
          {filteredProducts.map((product) => (
            <div key={product._id} className="product-card">
              <Link to={`/ProductDetail/${product._id}`} className="product-link">
                <img src={product.imageUrl} alt={product.name} />
                <h2 className="product-name">{product.name}</h2>
                <div
                  onClick={() => addToCart(product._id)}
                  className={`cart-icon ${isAnimating[product._id] ? "animate" : ""}`}
                >
                  <i className="fa fa-cart-plus fa-2x"></i>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
