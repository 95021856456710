// Header.js
import React, { useEffect, useState } from "react";
import { FaShoppingCart, FaSearch } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import PngItem_Logo from "../Images/PngItem_Logo.png";
import flamenco_dancer from "../Images/flamenco_dancer.jpg";
import axios from "axios";
import { useSelector } from "react-redux";

const Header = () => {
  const [cartItems, setCartItems] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const [showData, setShowData] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/cart");
        setCartItems(response.data);
      } catch (err) {
        console.error("Error fetching cart items");
      }
    };

    fetchCartItems();
  }, [cartItems.length]);

  const handleClick = () => {
    setShowData(!showData);
  };
  return (
    <header
      className="header"
      style={
        location.pathname === "/"
          ? {}
          : { position: "relative", backgroundColor: "rgb(48, 31, 14)" }
      }
    >
      {/* Logo */}
      <div className="logo">
        <h1>
          <Link to="/">
            <img src={PngItem_Logo} style={{ width: "60px" }} />
          </Link>
        </h1>
      </div>
      <nav className="nav">
        <ul className={` ${showData && "showData"}`}>
          <li>
            <Link onClick={handleClick} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="/products">
              Products
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="/about">
              About
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <div onClick={handleClick}>
          <i class="fa fa-bars fa-2x"></i>{" "}
        </div>

        <div className="cart">
          <Link to="/cart" style={{ color: "white", fontSize:"20px"}}>
            <FaShoppingCart style={{fontSize:"25px"}} />
            <span className="cart-count">{cartItems.length}</span>
          </Link>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>{userData?.username}</div>
          <Link to="/login">
            {userData?.profileImage ? (
              <img
                src={`http://localhost:5000/${userData?.profileImage}`}
                style={{
                  borderRadius: "100px",
                  height: "40px",
                  width: "40px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <i style={{fontSize:"30px",color:"white"}} class="fa fa-sign-in-alt"></i>
            )}
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
